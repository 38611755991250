import React from 'react';
import FormattedCurrency from 'react-formatted-currency';
import Lightbox from 'react-images';

export class TableInstallation extends React.Component {

    state = {
        lightboxIsOpen: false,
        photos: [],
        currentImage: 0,
    };

    handleChangePhotos = (service) => {
        let photos = [];
        if (service.fotos.data.length > 0) {
            service.fotos.data.map(photo => {
                photos.push({src: photo.src})
            });
        }

        this.setState({
            lightboxIsOpen: true,
            photos: photos,
            photosCurrent: service.fotos,
        })
    };

    closeLightbox = () => {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    };

    gotoPrevious = () => {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    };

    gotoNext = () => {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    };

    render() {
        const {services} = this.props;
        return (
            <div id={'instalacion'} className="Conteiner-table">
                <table className="table-installation">
                    <Lightbox
                        images={this.state.photos}
                        isOpen={this.state.lightboxIsOpen}
                        onClickPrev={this.gotoPrevious}
                        onClickNext={this.gotoNext}
                        onClose={this.closeLightbox}
                        imageCountSeparator={' / '}
                        currentImage={this.state.currentImage}
                        backdropClosesModal={true}
                        // customControls={[
                        //     <i className="icon-trash"
                        //        style={{color: 'white', fontSize: 28, marginLeft: 25, cursor: 'pointer'}}
                        //        onClick={() => this.handleEliminate()}
                        //     />]}
                    />
                    <thead>
                    <tr>
                        <th>Codigo servicio</th>
                        <th>Fecha de servicio</th>
                        <th>Tipo de servicio</th>
                        <th>Tipo de proyecto</th>
                        <th>Tipo de profesional</th>
                        <th>Descripcíon del servicio</th>
                        <th>Producto Ingetem</th>
                        <th>Productos</th>
                        <th>Marca del producto</th>
                        <th>Descripción del producto</th>
                        <th>Medidas del producto</th>
                        <th>Insumos</th>
                        <th>Referencia del insumo</th>
                        <th>Documentos</th>
                        <th>Imagenes</th>
                        <th>Nombre cliente</th>
                        <th>Dirección del servicio</th>
                        <th>País</th>
                        <th>Ciudad</th>
                        <th>Telefono cliente</th>
                        <th>Nombre gerente lider</th>
                        <th>Telefono gerente lider</th>
                        <th>Puntaje gerente lider</th>
                        <th>Puntaje del servicio</th>
                        <th>Comentario</th>
                        {/*<th>Total servicio</th>*/}
                        {/*<th>Total servicio para Ingetem</th>*/}
                        {/*<th>Total servicio para gerente lider</th>*/}
                        <th>Total de la visita</th>
                        <th>Total visita para Ingetem</th>
                        <th>Total visita para gerente lider</th>
                        <th>Metodo de pago</th>
                        <th>Estado</th>
                        <th>Confirmar transferencia de la cotización</th>
                        <th>Cotización</th>
                        <th>Pasos</th>
                    </tr>
                    </thead>
                    <tbody>
                    {services.length > 0 && services.map((service, index) => (
                        <tr key={index}>
                            <td>{service.id}</td>
                            <th>{service.fecha_creacion.substring(0, 10)}</th>
                            <td>{service.categoria.nombre}</td>
                            <td>{service.projecto.nombre}</td>
                            <td>{service.locacion === 'Interior' ? 'Independiente': 'Empresa de profesionales'}</td>
                            <td>{service.descripcion === null ? "Sin descripción del servicio" : service.descripcion}</td>
                            <td>{service.producto_roca === true ? "Si" : "No"}</td>
                            <td>
                                {service.productos.data > 0 ?
                                    service.productos.data.length > 0 && service.productos.data.map((product, index) => (
                                        <li key={index}>{product.hasOwnProperty('datos_producto') ? product.datos_producto.nombre : 'Producto usuario'}</li>
                                    )) :
                                    service.cotizacion.producto_cotizacion.data.length > 0 && service.cotizacion.producto_cotizacion.data.map((product, index) => (
                                        <li key={index}>{product.hasOwnProperty('datos_producto') ? product.datos_producto.nombre : ''}</li>
                                    ))}
                            </td>
                            <td>
                                {service.productos.data > 0 ?
                                    service.productos.data.length > 0 && service.productos.data.map((product, index) => (
                                        <li key={index}>{product.hasOwnProperty('datos_producto') ? product.datos_producto.marca : product.marca}</li>
                                    )) :
                                    service.cotizacion.producto_cotizacion.data.length > 0 && service.cotizacion.producto_cotizacion.data.map((product, index) => (
                                        <li key={index}>{product.hasOwnProperty('datos_producto') ? product.datos_producto.marca : ''}</li>
                                    ))}
                            </td>
                            <td>
                                {service.productos.data > 0 ?
                                    service.productos.data.length > 0 && service.productos.data.map((product, index) => (
                                        <li key={index}>{product.hasOwnProperty('datos_producto') ? product.datos_producto.descripcion : product.descripcion}</li>
                                    )) :
                                    service.cotizacion.producto_cotizacion.data.length > 0 && service.cotizacion.producto_cotizacion.data.map((product, index) => (
                                        <li key={index}>{product.hasOwnProperty('datos_producto') ? product.datos_producto.descripcion : ''}</li>
                                    ))}
                            </td>
                            <td>
                                {service.productos.data > 0 ?
                                    service.productos.data.length > 0 && service.productos.data.map((product, index) => (
                                        <li key={index}>{product.hasOwnProperty('datos_producto') ? product.datos_producto.medidas : product.medidas}</li>
                                    )) :
                                    service.cotizacion.producto_cotizacion.data.length > 0 && service.cotizacion.producto_cotizacion.data.map((product, index) => (
                                        <li key={index}>{product.hasOwnProperty('datos_producto') ? product.datos_producto.medidas : ''}</li>
                                    ))}
                            </td>
                            <td>
                                {service.insumos.data.length > 0 && service.insumos.data.map((supply, index) => (
                                    <li key={index}>{supply.insumo.data.nombre} x{supply.cantidad}</li>
                                ))}
                            </td>
                            <td>
                                {service.insumos.data.length > 0 && service.insumos.data.map((supply, index) => (
                                    <li key={index}>{supply.insumo.data.referencia}</li>
                                ))}
                            </td>
                            <td>
                                {service.documentos.data.length > 0 && service.documentos.data.map((document, index) => (
                                    <a key={index} href={document.src} target="_blank">
                                        <button className="Btn-item2-table">
                                            {document.nombre}
                                            <i className="icon-pencil styles-icons"/>
                                        </button>
                                    </a>
                                ))}
                                {service.documentos.data.length === 0 && (
                                    <p>Sin documentos</p>
                                )}
                            </td>
                            <td>
                                {service.fotos.data.length > 0 && (
                                    <button className="Btn-item-table"
                                            onClick={() => this.handleChangePhotos(service)}>
                                        Ver fotos
                                        <i className="icon-pencil styles-icons"/>
                                    </button>
                                )}
                                {service.fotos.data.length === 0 && (
                                    <p>Sin fotos</p>
                                )}
                            </td>
                            <td>{service.usuario.nombre}</td>
                            <td>{service.direccion}</td>
                            <td>{service.pais.nombre}</td>
                            <td>{service.ciudad.nombre}</td>
                            <td>{service.usuario.telefono}</td>
                            <td>{service.gerente_lider.nombre}</td>
                            <td>{service.gerente_lider.telefono_contacto}</td>
                            <td>{service.hasOwnProperty('gerente_lider') ? service.gerente_lider.puntaje_gerente_lider : ''}</td>
                            <td>{service.puntaje_gerente_lider}</td>
                            <td>{service.comentario_usuario}</td>
                            {/*<td>*/}
                            {/*    <FormattedCurrency*/}
                            {/*        amount={service.total_servicio}*/}
                            {/*        currency={service.pais.moneda}*/}
                            {/*        symbol={service.pais.simbolo_moneda}*/}
                            {/*        locale={service.ubicacion}*/}
                            {/*        parts={['code', 'number', 'symbol']}*/}
                            {/*        render={({symbol, number}) => {*/}
                            {/*            return `${symbol} ${number}`*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*    <FormattedCurrency*/}
                            {/*        amount={service.total_servicio_roca}*/}
                            {/*        currency={service.pais.moneda}*/}
                            {/*        symbol={service.pais.simbolo_moneda}*/}
                            {/*        locale={service.ubicacion}*/}
                            {/*        parts={['code', 'number', 'symbol']}*/}
                            {/*        render={({symbol, number}) => {*/}
                            {/*            return `${symbol} ${number}`*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*    <FormattedCurrency*/}
                            {/*        amount={service.total_servicio_gerente_lider}*/}
                            {/*        currency={service.pais.moneda}*/}
                            {/*        symbol={service.pais.simbolo_moneda}*/}
                            {/*        locale={service.ubicacion}*/}
                            {/*        parts={['code', 'number', 'symbol']}*/}
                            {/*        render={({symbol, number}) => {*/}
                            {/*            return `${symbol} ${number}`*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</td>*/}
                            <td>
                                {service.pasar_instalar === 1 ?
                                    <p>
                                        Servicio ya pago en el diseño
                                    </p>
                                    :
                                    <FormattedCurrency
                                        amount={service.total_visita}
                                        currency={service.pais.moneda}
                                        symbol={service.pais.simbolo_moneda}
                                        locale={service.ubicacion}
                                        parts={['code', 'number', 'symbol']}
                                        render={({symbol, number}) => {
                                            return `${symbol} ${number}`
                                        }}
                                    />}

                            </td>
                            <td>
                                {service.pasar_instalar === 1 ?

                                    <p>
                                        Servicio ya pago en el diseño
                                    </p>
                                    : <FormattedCurrency
                                        amount={service.total_visita_roca}
                                        currency={service.pais.moneda}
                                        symbol={service.pais.simbolo_moneda}
                                        locale={service.ubicacion}
                                        parts={['code', 'number', 'symbol']}
                                        render={({symbol, number}) => {
                                            return `${symbol} ${number}`
                                        }}
                                    />}

                            </td>
                            <td>
                                {service.pasar_instalar === 1 ?
                                    <p>
                                        Servicio ya pago en el diseño
                                    </p>
                                    :
                                    <FormattedCurrency
                                        amount={service.total_visita_gerente_lider}
                                        currency={service.pais.moneda}
                                        symbol={service.pais.simbolo_moneda}
                                        locale={service.ubicacion}
                                        parts={['code', 'number', 'symbol']}
                                        render={({symbol, number}) => {
                                            return `${symbol} ${number}`
                                        }}
                                    />}

                            </td>
                            <td>
                                {service.pasar_instalar === 1 ?
                                    <p>Servicio ya pago en el diseño</p>
                                    :
                                    <>
                                        {service.metodo_pago.nombre === 'Transferencia bancaria'
                                            ? <>
                                                {service.metodo_pago.nombre}
                                                {service.estado.nombre === 'Pago pendiente' && (
                                                    <div className={'button-confirmed-transfer'} onClick={() => {
                                                        this.props.showModal('transfer');
                                                        this.props.serviceFocus(service);
                                                    }}>
                                                        Confirmar pago
                                                    </div>
                                                )}
                                            </>
                                            : <>
                                                <p>{service.metodo_pago.nombre}</p>
                                            </>
                                        }
                                    </>
                                }
                            </td>
                            <td>
                                {service.estado.nombre === 'Programado' && (
                                    <div className="container-style">
                                        <div className="state-text">{service.estado.nombre}</div>
                                        <div className="state-1"/>
                                    </div>
                                )}
                                {service.estado.nombre === 'Iniciado' && (
                                    <div className="container-style">
                                        <div className="state-text">{service.estado.nombre}</div>
                                        <div className="state-2"/>
                                    </div>
                                )}
                                {service.estado.nombre === 'Curso' && (
                                    <div className="container-style">
                                        <div className="state-text">{service.estado.nombre}</div>
                                        <div className="state-3"/>
                                    </div>
                                )}
                                {service.estado.nombre === 'Finalizado' && (
                                    <div className="container-style">
                                        <div className="state-text">{service.estado.nombre}</div>
                                        <div className="state-4"/>
                                    </div>
                                )}
                                {service.estado.nombre === 'Pago pendiente' && (
                                    <div className="container-style">
                                        <div className="state-text">{service.estado.nombre}</div>
                                        <div className="state-2"/>
                                    </div>
                                )}
                            </td>
                            <td>
                                {service.cotizacion.estado_pago === 'Pendiente' && service.cotizacion.metodo_pago === 'Transferencia bancaria'
                                    ? <>
                                        {service.cotizacion.metodo_pago}
                                        {service.cotizacion.estado_pago === 'Pendiente' && (
                                            <div className={'button-confirmed-transfer'} onClick={() => {
                                                this.props.showModal('transfer');
                                                this.props.quotationFocus(service.cotizacion);
                                            }}>
                                                Confirmar pago
                                            </div>
                                        )}
                                    </>
                                    : <>
                                        <p>No requiere confirmación</p>
                                    </>
                                }
                            </td>
                            <td>
                                {service.cotizacion.precio_instalation !== null
                                    ? <button className="Btn-item-table"
                                              onClick={() => {
                                                  this.props.showModal('quotation');
                                                  this.props.quotationFocus(service.cotizacion);
                                              }}>
                                        Ver cotización
                                        {/*<i className="icon-pencil styles-icons"/>*/}
                                    </button>
                                    : 'Aun no tiene cotización'
                                }
                            </td>
                            <td>
                                {service.pasos.data.length > 0
                                    ? <button className="Btn-item-table"
                                              onClick={() => {
                                                  this.props.showModal('steps');
                                                  this.props.serviceFocus(service);
                                              }}>
                                        Ver pasos
                                        {/*<i className="icon-pencil styles-icons"/>*/}
                                    </button>
                                    : 'No tiene pasos'
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}
